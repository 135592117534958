<template>
  <div
    :class="[
      'cm-tag',
      type ? `cm-tag--${type}` : '',
      { 'cm-tag--border-color': borderColor },
    ]"
  >
    <div class="cm-tag__icon">
      <slot name="icon">
        <FileIcon :file="{ filename: label }" class="cm-tag__icon-file" />
      </slot>
    </div>
    <p class="cm-tag__title">{{ label }}</p>
    <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" @click="$emit('close')" v-if="canClose" v-bind:class="'cm-tag__close'" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path fill-rule="evenodd" clip-rule="evenodd" d="M17.657 7.757a1 1 0 00-1.414-1.414L12.707 9.88a1 1 0 01-1.414 0L7.757 6.343a1 1 0 00-1.414 1.414l3.536 3.536a1 1 0 010 1.414l-3.536 3.536a1 1 0 101.414 1.414l3.536-3.536a1 1 0 011.414 0l3.536 3.536a1 1 0 101.414-1.414l-3.536-3.536a1 1 0 010-1.414l3.536-3.536z"/></svg>
  </div>
</template>

<script>
import FileIcon from '@component/FileIcon';
export default {
  name: 'CMTag',
  components: {
    FileIcon,
  },
  props: {
    label: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: '',
    },
    canClose: {
      type: Boolean,
      default: false,
    },
    borderColor: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
<style lang="scss" scoped>
.cm-tag {
  display: flex;
  padding: 4px 8px;
  border-radius: 8px;
  border: 1px solid;
  font-size: 0.875rem;
  align-items: center;
  border-color: var(--color-gray-03);
  &--border-color {
    &[class*='pdf'] {
      border-color: #f68989;
      color: #e81d1d;
    }
    &[class*='board'] {
      border-color: #d4f185; // color-lime-03
      color: #729611; // color-lime-05
    }
    &[class*='doc'] {
      border-color: #97a1ec; // color-blue-03
      color: #2133da; // color-blue-05
    }
  }
}

.cm-tag__icon-file {
  fill: var(--color-gray-04);
}

.cm-tag__title {
  white-space: nowrap;
  max-width: 240px;
  text-overflow: ellipsis;
  overflow: hidden;
}
.cm-tag__icon {
  display: inline-flex;
  margin-right: 4px;
  svg,
  img {
    @include icon-size(1.25);
  }
  .cm-tag--board & {
    path {
      fill: #9ccb18;
    }
  }
}
.cm-tag__close {
  @include icon-size(1.25);
  margin-left: 8px;
  cursor: pointer;
  path {
    fill: var(--color-gray-04);
  }
}
</style>
